"use client";

import classNames from "classnames";
import { motion } from "framer-motion";
import { HTMLAttributes, forwardRef } from "react";
import { CloseButton } from "../buttons";

interface Props extends HTMLAttributes<HTMLDialogElement> {
  setShowModal?: (arg: boolean) => void;
  id?: string;
  className?: string;
  width?: string;
  height?: string;
  translateX?: string;
  translateY?: string;
  maxWidth?: HTMLAttributes<HTMLDialogElement>["style"]["maxWidth"];
  maxHeight?: HTMLAttributes<HTMLDialogElement>["style"]["maxHeight"];
  bodyStyle?: HTMLAttributes<HTMLDialogElement>["style"];
}

export const ModalDesktop = forwardRef(function ModalDesktop(
  { children, id, setShowModal, width = "80vw", height, translateX = "0", translateY = "0", maxWidth, maxHeight, className, bodyStyle, ...props }: Props,
  ref: any
) {
  const style = {
    width: width,
    height: height,
    maxWidth: maxWidth,
    maxHeight: maxHeight,
    translate: `${translateX} ${translateY}`,
    ...props.style,
  };

  const contentVariants = {
    visible: { y: 0, opacity: 1, scale: 1 },
    hidden: { y: 500, opacity: 0 },
  };

  return (
    <motion.dialog
      ref={ref}
      className={classNames(className, "modal")}
      id={id ? id : undefined}
      key={id ?? "modal"}
      onClick={(e) => e.stopPropagation()}
      style={style}
      open={false}
      onCancel={(e) => {
        if (setShowModal) setShowModal(false);
        else {
          e.preventDefault();
        }
      }}
      onMouseDown={(e) => {
        e.target == ref?.current && setShowModal && setShowModal(false);
      }}
      variants={contentVariants}
    >
      {setShowModal && <CloseButton style={{ right: "10px", top: "10px", position: "absolute" }} className="p-1" cb={() => setShowModal(false)} />}
      <div className="body" style={{ ...bodyStyle }}>
        {children}
      </div>
    </motion.dialog>
  );
});
