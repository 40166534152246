'use client';
import Image from 'next/image';
import { HTMLAttributes } from 'react';
import { useExerciceConnectorAppaired } from "../../../../utilities/hooks/connectors/useExerciceConnectorAppaired";
import { AccountingFirmConnectorType, EntryExerciceType } from '../../../../utilities/redux/types';
import { Shape } from '../../../components/shapes';
import { AccountingFirmConnectorIcon } from '../accountingFirmConnectorIcon/AccountingFirmConnectorIcon';

interface Props extends HTMLAttributes<HTMLDivElement> {
  exercice: EntryExerciceType;
  connectors: AccountingFirmConnectorType[];
  size?: number;
}

export function ExercicePairingIconsGroup({ exercice, connectors, size = 22, ...props }: Props) {
  const appairedWith = exercice.pairingKey ? useExerciceConnectorAppaired(connectors, exercice.pairingKey) : [];

  return (
    <div {...props} id="exercice_pairingKeys">

      {!appairedWith || appairedWith?.length == 0 ? (
        <div
          id="logo"
          style={{
            position: "relative",
            width: size * 2,
            aspectRatio: 1,
          }}
        >
          <Image
            alt="logo"
            src={"/logo_carre.png"}
            style={{
              objectFit: "contain",
            }}
            fill={true}
          />
        </div>
      ) : (
        <div id="exercice_pairingKeys">
          {appairedWith?.map((connector) => {
            return (
              <Shape
                size={size * 2}
                key={`${exercice.id}_${connector.id}`}
                style={{ border: "solid 1px var(--color-gray4)" }}
              >
                <AccountingFirmConnectorIcon item={connector.accountingFirmConnectorType} size={size} />
              </Shape>
            );
          })}
        </div>
      )}
    </div>
  );
}