import { useEffect, useState } from "react";
import { CircleLoader } from "../circleLoader/CircleLoader";

type Props = {
  size?: number;
  type?: "gray" | "info" | "success" | "fail";
  legend?: string;
};

export default function BlocLoader({ size = 80, type = "gray", legend = "Chargement en cours..." }: Props) {
  const [colorLoader, setColorLoader] = useState("");
  const [colorSecondaryLoader, setColorSecondaryLoader] = useState("");

  useEffect(() => {
    if (type == "gray") {
      setColorLoader("gray20");
      setColorSecondaryLoader("gray3");
    } else if (type == "info") {
      setColorLoader("active");
      setColorSecondaryLoader("infoBackground-hover");
    } else if (type == "success") {
      setColorLoader("success");
      setColorSecondaryLoader("successBackground-hover");
    } else if (type == "fail") {
      setColorLoader("error");
      setColorSecondaryLoader("errorBackground-hover");
    }
  }, [type]);

  return (
    <div className={"bloc-loader " + type}>
      <CircleLoader width={size + "px"} color={"var(--color-" + colorLoader + ")"} secondaryColor={"var(--color-" + colorSecondaryLoader + ")"} />
      <p className="text-center mt-1 fw-600 fs-100">{legend}</p>
    </div>
  );
}
