"use client";

import { HTMLAttributes, forwardRef } from "react";
// Utilities
import { useMediaQuery } from "../../../../utilities/hooks/useMediaQuery";

interface Props extends HTMLAttributes<HTMLDivElement> {
  columns?: number;
  space?: number;
  columnGap?: number;
  rowGap?: number;
  children: any | any[];
  alignItems?: HTMLAttributes<HTMLDivElement>["style"]["alignItems"];
  justifyContent?: HTMLAttributes<HTMLDivElement>["style"]["justifyContent"];
  autoFit?: number;
  autoFill?: number;
  template?: HTMLAttributes<HTMLDivElement>["style"]["gridTemplateColumns"];
  mediaQuery?: number;
  overflow?: HTMLAttributes<HTMLDivElement>["style"]["overflow"];
  id?: string;
}

export const Grid = forwardRef(function Grid(
  {
    columns,
    space = 15,
    columnGap,
    rowGap,
    children,
    alignItems = "start",
    justifyContent,
    autoFit,
    autoFill,
    template,
    mediaQuery = 920,
    overflow,
    id,
    ...props
  }: Props,
  ref: any
) {
  const mediaQueryBoolean = useMediaQuery(mediaQuery);

  const defineTemplateColumns = () => {
    if (mediaQueryBoolean) {
      return "1fr";
    }
    if (columns && typeof columns === "number") {
      return `repeat(${columns}, 1fr)`;
    }
    if (autoFit) {
      return `repeat(auto-fit, minmax(${autoFit}px, 1fr))`;
    }
    if (autoFill) {
      return `repeat(auto-fill, minmax(${autoFill}px, 1fr))`;
    }
    if (template) {
      return template;
    }
  };

  props.style?.gridTemplateRows && console.log(props.style.gridTemplateRows);

  return (
    <div
      ref={ref}
      {...props}
      id={id && id}
      className={props.className ? "grid " + props.className : "grid"}
      style={{
        display: "grid",
        gridTemplateColumns: defineTemplateColumns(),
        columnGap: columnGap ?? space,
        rowGap: rowGap ?? space,
        alignItems,
        justifyContent,
        overflow: overflow,
        gridTemplateRows: props.style?.gridTemplateRows ?? "1fr",
        ...props.style,
      }}
    >
      {children}
    </div>
  );
});
